import "./about.scss";

const About = () => {
  document.title = "About Us";
  return (
    <div>
      <div className="container-lg">
        <div className="row mb-3">
          <div className="col-lg-6 col-md-4 col-sm-12 about-main">
            <div className="about-title">ABOUT US</div>
            <div className="about-description">
              At Noor Air Conditioners, we are dedicated to providing our
              customers with high-quality repair services for their household
              appliances. We specialize in repairing
            </div>
            {/* <div className="list-title">Our Labels and Stickers:-</div> */}
            <ul>
              <li>Air Conditioners</li>
              <li>Refrigerators</li>
              <li>Washing Machines</li>
              <li>Microwaves</li>
              <li>Geysers</li>
            </ul>
          </div>
          <div
            className="col-lg-3 col-md-4 col-sm-12 p-3"
            style={{
              border: "1px solid #eee",
              borderRadius: "2px",
              marginTop: "auto",
            }}
          >
            <img
              src="https://gsplabels.com/img/office.jpg"
              alt=""
              className="about-image"
            />
          </div>
          <div
            className="col-lg-3 col-md-4 col-sm-12 p-3"
            style={{
              border: "1px solid #eee",
              borderRadius: "2px",
              marginTop: "auto",
            }}
          >
            <img
              src="https://gsplabels.com/img/office.jpg"
              alt=""
              className="about-image"
            />
          </div>
        </div>
        <div className="about-description-new">
          We understand the inconvenience that comes with a broken appliance,
          which is why we provide quick and efficient repair services to
          minimize any downtime for you. Our goal is to ensure that your
          appliances are repaired promptly, and we use only high-quality
          replacement parts to guarantee that the repairs last. We pride
          ourselves on our exceptional customer service, and our friendly and
          knowledgeable staff is always ready to assist you with any inquiries
          or concerns you may have.
          <br />
          <br />
          At Noor Air Conditioners, we are committed to providing sustainable
          repair solutions. We believe that promoting sustainable practices is
          not only a responsibility but also an opportunity to create a better
          future for ourselves and future generations. By repairing your
          appliances instead of replacing them, we help reduce waste and support
          a more sustainable future.
          <br />
          <br />
          Join us in our mission to promote sustainability and prolong the
          lifespan of your household appliances by choosing Noor Air
          Conditioners for your repair needs. Contact us today to schedule an
          appointment and experience our exceptional repair services firsthand.
        </div>
      </div>
    </div>
  );
};

export default About;
