import { Fragment } from "react";
import "./footer.scss";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  return (
    <Fragment>
      <div className="footer-full">
        <div className="container-fluid footer-main">
          <div className="container-lg">
            <div className="row justify-content-between pt-5 pb-5 pr-5 main-footer">
              <div className="col-lg-5 col-sm-12">
                <div className="left-column">
                  {/* <img src={Logo} alt="logo" style={{ width: "25%" }} /> */}
                  <div
                    style={{
                      fontFamily: "Dudek Bold",
                      color: "white",
                      fontSize: "25px",
                    }}
                  >
                    Noor Air Conditioner
                  </div>
                  <div
                    style={{
                      marginTop: "20px",
                      color: "white",
                    }}
                  >
                    {/* <div className="company-name"> Opal Trading DMCC</div>
                    <div className="name">Sabi Aggarwal</div> */}
                    {/* <div className="designation">Operations Manager</div> */}
                    Shop No.4, Building Sana Comfort, 13 D-2, <br />
                    Sabah Akhtar Road, Near Waseem Bagh, <br /> Gulshan e Iqbal,
                    Karachi. <br /> <br />
                    T. 03142004996
                    <br />
                    noorairconditioner.pk@gmail.com
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-sm-12">
                {/* <div
                  style={{
                    color: "white",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "15px",
                  }}
                >
                  Site Map
                </div> */}
                <div>
                  <ul className="nav nav-footer-items">
                    <div className="footer-navbar-item">
                      <Link
                        className={
                          location.pathname === "/"
                            ? "nav-link-footer active-footer"
                            : "nav-link-footer"
                        }
                        to="/"
                      >
                        Home
                      </Link>
                    </div>
                    <div className="footer-navbar-item">
                      <Link
                        className={
                          location.pathname === "/contact-us"
                            ? "nav-link-footer active-footer"
                            : "nav-link-footer"
                        }
                        to="/contact-us"
                      >
                        Contact Us
                      </Link>
                    </div>
                    <div className="footer-navbar-item">
                      <Link
                        className={
                          location.pathname === "/about-us"
                            ? "nav-link-footer active-footer"
                            : "nav-link-footer"
                        }
                        to="/about-us"
                      >
                        About Us
                      </Link>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid footer-second">
          <div className="container-lg bottom-text">
            Copyright © 2023 Noor Air Conditioner. All rights reserved.
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
