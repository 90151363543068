import "./home.scss";
import { Link } from "react-router-dom";
// import Recycling from "../../static/Recycling.mp4";
import Slider from "react-slick";
import "../../components/header/header.scss";
import one from "../../static/2.jpg";
import two from "../../static/3.jpg";
import three from "../../static/4.jpg";
import four from "../../static/5.jpg";
import five from "../../static/6.jpg";

const Home = () => {
  document.title = "Noor Air Conditioner";
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  return (
    <>
      <Slider {...settings} style={{ width: "100%" }}>
        <div className="image-container">
          <img
            src={one}
            alt=""
            className="header-slider-image"
            style={{ objectPosition: "top" }}
          />{" "}
        </div>
        <div className="image-container">
          <img src={two} alt="" className="header-slider-image" />{" "}
        </div>
        <div className="image-container">
          <img src={three} alt="" className="header-slider-image" />{" "}
        </div>
        <div className="image-container">
          <img src={four} alt="" className="header-slider-image" />{" "}
        </div>
        <div className="image-container">
          <img src={five} alt="" className="header-slider-image" />{" "}
        </div>
      </Slider>{" "}
      <div className="container-lg mb-5"></div>
      <div className="container-lg text-center mb-5">
        <div className="services">Services</div>
        <div class="row">
          <div className="col-lg-4 home-card">
            <Link to="#">
              <img
                src="https://img.icons8.com/pastel-glyph/64/null/air-conditioner--v2.png"
                alt=""
                className="card-icon"
              />
              <div className="card-title">AC Repair Service</div>
              <div className="card-description">
                We offer quality labels that are manufactured using the best
                quality...{" "}
              </div>
              <div className="card-read-more">Read More..</div>
            </Link>
          </div>
          <div className="col-lg-4 home-card">
            <Link to="#">
              <img
                src="https://img.icons8.com/ios/50/null/fridge.png"
                alt=""
                className="card-icon"
              />
              <div className="card-title">Fridge Repair Service</div>
              <div className="card-description">
                We offer quality labels that are manufactured using the best
                quality...{" "}
              </div>
              <div className="card-read-more">Read More..</div>
            </Link>
          </div>
          <div className="col-lg-4 home-card">
            <Link to="#">
              <img
                src="https://img.icons8.com/pastel-glyph/64/null/washing-machine--v2.png"
                alt=""
                className="card-icon"
              />
              <div className="card-title">Washing Machines Repair Service</div>
              <div className="card-description">
                We offer quality labels that are manufactured using the best
                quality...{" "}
              </div>
              <div className="card-read-more">Read More..</div>
            </Link>
          </div>
          <div className="col-lg-4 home-card">
            <Link to="#">
              <img
                src="https://img.icons8.com/ios/50/null/microwave.png"
                alt=""
                className="card-icon"
              />
              <div className="card-title">Microwave Repair Service</div>
              <div className="card-description">
                We offer quality labels that are manufactured using the best
                quality...{" "}
              </div>
              <div className="card-read-more">Read More..</div>
            </Link>
          </div>
          <div className="col-lg-4 home-card">
            <Link to="#">
              <img
                src="https://img.icons8.com/external-smashingstocks-hand-drawn-black-smashing-stocks/99/null/external-water-boiler-electronics-and-appliances-smashingstocks-hand-drawn-black-smashing-stocks.png"
                alt=""
                className="card-icon"
                style={{ width: "90px" }}
              />
              <div className="card-title">Geyser Repair Service</div>
              <div className="card-description">
                We offer quality labels that are manufactured using the best
                quality...{" "}
              </div>
              <div className="card-read-more">Read More..</div>
            </Link>
          </div>
        </div>
      </div>
      <div className="home-divider-new"></div>
      {/* <div class="divider-home">
        <div class="divider-icon-home">
          <img src={divider} alt="" className="divider-home-image" />
        </div>
      </div> */}
      <div className="container-lg mt-5 home-main">
        <div className="home-description">
          {/* <div className="home-bottom-title">Welcome to Opal Trading DMCC</div> */}
          Noor Air Conditioners is a reliable repair place that specializes in
          fixing household appliances such as air conditioners, refrigerators,
          washing machines, microwaves, and geysers. With years of experience,
          the technicians at Noor Air Conditioners are equipped with the
          knowledge and tools needed to quickly diagnose and repair any issue
          with your appliances, ensuring that they function at peak performance.{" "}
          <br />
          At Noor Air Conditioners, we understand the inconvenience that comes
          with a broken appliance. That's why we strive to provide quick and
          efficient services that minimize any downtime for you. Our team of
          experts is dedicated to ensuring that your appliances are repaired
          promptly, and we use only high-quality replacement parts to guarantee
          that the repairs last.
          <br />
          Noor Air Conditioners prides itself on its exceptional customer
          service. We believe in building lasting relationships with our
          clients, and our friendly and courteous staff is always ready to
          assist you with any questions or concerns you may have. We offer
          affordable pricing for all of our repair services, and we ensure that
          our customers receive value for their money.
          <br />
          Whether you have a malfunctioning air conditioner, refrigerator,
          washing machine, microwave, or geyser, Noor Air Conditioners is the
          go-to repair shop for all your appliance repair needs. With our team
          of experts, quick turnaround time, and exceptional customer service,
          you can trust that your appliances are in good hands. Contact us today
          to schedule an appointment and experience our top-notch repair
          services firsthand.
        </div>
      </div>
    </>
  );
};

export default Home;
