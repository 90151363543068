import "./contact.scss";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

const Contact = () => {
  const form = useRef();
  document.title = "Contact Us";
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          if (result?.text === "OK") {
            toast.success("Email Sent Successfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmitting(false);
            form.current.reset();
          }
        },
        (error) => {
          console.log(error);
          toast.error(error.text, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false);
        }
      );
  };
  return (
    <div>
      <div className="container-lg">
        <div className="row">
          <div className="col-lg-6 pt-5 pb-5 contact-main">
            <div className="contact-title">
              {" "}
              CONTACT <br /> US
            </div>
            <div className="contact-description">
              At Noor Air Conditioners, we value our customers and are always
              ready to assist you. If you have any questions, concerns or would
              like to book an appointment, please don't hesitate to get in touch
              with us. You can reach us through our phone number, email, or by
              filling out the contact form on our website. Our friendly and
              knowledgeable staff will be more than happy to help you with any
              inquiries you may have. We look forward to hearing from you soon!
            </div>
          </div>
          <div className="col-lg-6 p-3">
            <div className="contact-title-second">Get In Touch</div>
            <form ref={form} onSubmit={sendEmail}>
              <div className="form-row">
                <div className="form-column">
                  <input
                    type="text"
                    id="first_name"
                    placeholder="First Name"
                    className="first-name"
                    name="first_name"
                    required
                  />
                </div>
                <div className="form-column">
                  <input
                    type="text"
                    id="last_name"
                    placeholder="Last Name"
                    className="last-name"
                    name="last_name"
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <input
                    type="email"
                    id="email"
                    placeholder="Email"
                    className="email"
                    name="email"
                    required
                  />
                </div>
                <div className="form-column">
                  <input
                    type="number"
                    id="phone"
                    placeholder="Phone"
                    className="number"
                    name="phone"
                    required
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <textarea
                    id="address"
                    rows="2"
                    placeholder="Address"
                    className="address"
                    name="address"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="form-row">
                <div className="form-column">
                  <textarea
                    id="message"
                    rows="4"
                    placeholder="Your Message"
                    className="message"
                    name="message"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="text-center">
                <button
                  className="submit-button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <div style={{ padding: "0 15px" }}>
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
